import { Navigate, useRoutes } from "react-router-dom";
import SignupPage from "./pages/SignupPage";
import ConfirmOtpPage from "./pages/ConfirmOTPPage";
import YourDetails from "./pages/YourDetailsPage";
import ConfirmEmailOtpPage from "./pages/ConfirmEmailOtp";
import PanVerificationPage from "./pages/PanVerificationPage";
import AadhaarKYCPage from "./pages/AadharKYCPage";
import YourProfilePage from "./pages/YourProfilePage";
import LinkUsingIFSC from "./pages/LinkUsingIFSC";
import UploadDocumentsPage from "./pages/UploadDocumentsPage";
import NomineesPage from "./pages/NomineePage";
import EsignPage from "./pages/EsignPage";
import CongratulationsPage from "./pages/CongratulationsPage";
import WebcamDemo from "./Components/CameraModule/WebCamFaceDetection";
import TradingPreference from "./pages/TradingPreference";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPathName } from "./Components/redux/actions/pathNameActions";
import { useEffect } from "react";
import TermsandConditionsPage from "./pages/TermsandConditionsPage";
import KraEsignPage from "./pages/kraEsign";
import CompletedPage from "./pages/CompletedPage";
import PageNotFound from "./pages/PageNotFound";

import HomePage from "./pages/Pages copy/HomePage";
import HomePageEsign from "./pages/Pages copy/esign/HomePageEsign";
import PANCR from "./pages/PanCRDigilocker";
import Payments from "./pages/Payments/Payment";
import Updates from "./pages/Updates";
import Rekyc from "./pages/Rekyc";

export default function Router() {
  const pathName = useLocation().pathname;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPathName(pathName));
  }, [pathName]);

  const routes = useRoutes([
    // {
    //   path: "",
    //   element: <SignupPage />,
    // },
    {
      path: "",
      element: <SignupPage />,
    },
    // {
    //   path: "yourdetails",
    //   element: <EmailSignupPage />,
    // },
    {
      path: "token/:id",
      element: <SignupPage />,
    },
    {
      path: "confirm_otp",
      element: <ConfirmOtpPage />,
    },
    {
      path: "yourdetails",
      element: <YourDetails />,
    },
    {
      path: "confirmemailotp",
      element: <ConfirmEmailOtpPage />,
    },

    {
      path: "panverification",
      element: <PanVerificationPage />,
    },
    {
      path: "aadhaar",
      element: <AadhaarKYCPage />,
    },
    {
      path: "tradingPreference",
      element: <TradingPreference />,
    },
    {
      path: "yourprofile",
      element: <YourProfilePage />,
    },
    {
      path: "linkusingifsc",
      element: <LinkUsingIFSC />,
    },
    {
      path: "ipv",
      element: <WebcamDemo />,
    },

    {
      path: "uploaddocuments",
      element: <UploadDocumentsPage />,
    },
    {
      path: "payment",
      element: <Payments />,
    },
    {
      path: "updates",
      element: <Updates />,
    },
    {
      path: "rekyc",
      element: <Rekyc />,
    },
    {
      path: "nominee",
      element: <NomineesPage />,
    },
    {
      path: "kraesign",
      element: <KraEsignPage />,
    },
    {
      path: "esign",
      element: <EsignPage />,
    },

    {
      path: "termsandconditions",
      element: <TermsandConditionsPage />,
    },
    {
      path: "/*",
      element: <PageNotFound />,
    },

    {
      path: "congratulations",
      element: <CongratulationsPage />,
    },
    {
      path: "completed",
      element: <CompletedPage />,
    },
    {
      path: "pancr/*",
      element: <PANCR />,
    },
    {
      path: "ekyc_aadhaar/*",
      element: <HomePage />,
    },
    {
      path: "ekyc_esign/*",
      element: <HomePageEsign />,
    },
    {
      path: "/*",
      element: <PageNotFound />,
    },
  ]);

  return routes;
}
