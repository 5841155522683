import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  Divider,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormHelperText,
  Grid,
  Chip,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { checkUserLoggedIn } from "../shared/commonfunctions";
import SelectDropdown from "../Components/SelectDropdown/SelectDropdown";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./YourProfile.css";
import {
  fetchGetDropdownValuesAPICall,
  yourProfileDetailsAPICall,
  startOverAPICall,
  stageDetailsAPICall,
  CommonDropDownAPICall,
} from "../shared/APICalls";
import { IYourProfileDetails, IYourProfilePrefillData } from "../shared/dtos";
import routesData from "../shared/routes.json";
import ProfileStyles from "../pages/Styles/YourProfile.module.scss";
import KRAFetchedDetails from "./KRAFetchedDetails";
import StageLayout from "../Layouts/StageLayout";
import icon from "../Components/assets/Images/iconnew.svg";
import jwt_decode from "jwt-decode";
import BackDrop from "../Components/BackDrop";
import Dropdown from "../Components/SelectDropdown/DropDown";
import StartOverBackDrop from "../Components/StartOverBackDrop";
import ErrorSnackBar from "../Components/SnackBars/ErrorSnackBar";
import { confirmAlert } from "react-confirm-alert";
import SnackBar from "../Components/SnackBars/SnackBar";

const schema = Yup.object().shape({
  fatherFullName: Yup.string()
    .required("Father First Name is Required")
    .max(50)
    .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
    .transform((value: any) => value && value.toUpperCase()),
  motherFullName: Yup.string()
    .required("Mother First Name is Required")
    .max(50)
    .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
    .transform((value: any) => value && value.toUpperCase()),
  annualIncome: Yup.object().required("Please select an option"),
  tradingExperience: Yup.object().required("Please select an option"),
  occupation: Yup.object().required("Please select an option"),
  occupationIfOthers: Yup.string().when(
    "occupation",
    (occupation: any, schema) => {
      if (
        occupation.length &&
        occupation[0] &&
        occupation[0].value === "Others"
      ) {
        return schema
          .required("Occupation others is Required")
          .matches(/^[a-zA-Z ]*$/, "Enter Valid name")
          .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
          .max(50)
          .transform((value: any) => value && value.toUpperCase());
      }

      return schema;
    }
  ),

  areYouPoliticallyExposed: Yup.object().required("Please select an option"),
  maritalStatus: Yup.object().required("Marital Status is required"),
});

const annualmappingvalues = {
  "Below 1 Lakh": "below_one_lakh",
  "Above 1 Crore": "above_one_crore",
  "5 - 10 Lakhs": "five_to_ten_lakh",
  "1 - 5 Lakhs": "one_to_five_lakh",
  "10 - 25 Lakhs": "ten_to_twentyfive_lakh",
  "25 Lakhs - 1 Crore": "twentyfive_lakh_to_one_crore",
};
const occupationmappingvalues = {
  Others: "others",
  Retired: "retired",
  Student: "student",
  Business: "business",
  Professional: "professional",
  Agriculturist: "agriculturist",
  "Public Sector": "public_sector",
  "Self Employed": "self_employed",
  "Private Sector": "private_sector",
  "Government Service": "government_service",
  "Homemaker / Housewife": "homemaker_housewife",
};
const tradingexpmappingvalues = {
  New: "new",
  "5 -10 Years": "five_to_ten",
  "1 - 5 Years": "one_to_five",
  "15+ years": "fifteen_plus",
  "10 - 15 Years": "ten_to_fifteen",
};

const PoliticalOptions = [
  { id: true, sequence: 1, value: "Yes" },
  { id: false, sequence: 1, value: "No" },
];
const MaritalOptions = [
  { id: "single", sequence: 1, value: "Single" },
  { id: "married", sequence: 1, value: "Married" },
];

export default function YourProfilePage() {
  const navigate = useNavigate();

  //Loaders
  const [loading, setloading] = useState(false);
  const [prefilloading, setprefilloading] = useState(true);

  const [occupationOthers, setOccupationOthers] = useState<any>();
  const [occupationOther, setOthersDataFeild] = useState<any>();
  const [dropDownData, setDropDownData] = useState<any>({});
  const [annualDropDownData, setAnnualDropDownData] = useState<any>([]);
  const [tradingExperienceDropdownData, settradingExperienceDropdownData] =
    useState<any>([]);
  const [occupationDropdownData, setoccupationDropdownData] = useState<any>([]);
  const [displayFatherNameField, setdisplayFatherNameField] =
    useState<any>(true);

  const [editUpdates, setEditUpdates] = useState<any>(true);
  const [startOverloading, setstartOverloading] = useState(false);
  const [completedStages, setCompletedStages] = useState<any>();
  const [prefillData, setprefillData] = useState<IYourProfilePrefillData>({
    fatherFullName: "",
    motherFullName: "",
    annualIncome: "",
    tradingExperience: "",
    occupation: "",
    areYouPoliticallyExposed: "",
    maritalStatus: "",
    occupationIfOthers: "",
    // settlements: {
    //   id: "quarter",
    //   sequence: 1,
    //   value: "Once every calender quarter",
    // },
  });
  const [noeditableData, setnoEditableData] = useState<any>([]);
  const [backdrop, setBackDrop] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [message, setMessage] = useState("");

  const navigationData = routesData;
  const [user, setUser] = useState({});

  function setOthersData(data: any) {
    console.log(data);

    setOthersDataFeild(data);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    checkUserLoggedIn(navigate);
    let annualFetchedData;
    let tradingFetchedData;
    let OccupationFetchedData;
    setprefilloading(true);
    let dd_type = "profile";
    CommonDropDownAPICall(dd_type)
      .then((response: any) => {
        const responseData = response.data.data;

        console.log("responseData", responseData);
        setAnnualDropDownData(
          responseData.annual_income
            .map((a: any) => {
              return a;
            })
            .sort((a: any, b: any) => a.sequence - b.sequence)
        );
        annualFetchedData = responseData.annual_income.map((a: any) => {
          return a;
        });

        settradingExperienceDropdownData(
          responseData.trading_exp
            .map((a: any) => {
              return a;
            })
            .sort((a: any, b: any) => a.sequence - b.sequence)
        );

        tradingFetchedData = responseData.trading_exp.map((a: any) => {
          return a;
        });
        setoccupationDropdownData(
          responseData.occupation.map((a: any) => {
            return a;
          })
        );

        OccupationFetchedData = responseData.occupation.map((a: any) => {
          return a;
        });

        // API Call
        let obj: any = {
          stage: "customer_details",
        };

        stageDetailsAPICall(obj)
          .then((response: any) => {
            setprefilloading(false);
            setEditUpdates(false);
            const responseData = response.data;
            // console.log("responseData", responseData.data.data);

            // if (responseData.data.StageDetails.noneditable.FatherName){
            //   setdisplayFatherNameField(false)
            // }

            // setnoEditableData(responseData.data.StageDetails.noneditable);

            setCompletedStages(responseData.data.completed_stages);
            const annualIncomeReturned = annualFetchedData.filter(
              (data: any) => data.value === responseData.data.data.annual_income
            )[0];
            const tradingExperienceReturned = tradingFetchedData.filter(
              (data: any) => data.value === responseData.data.data.trading_exp
            )[0];
            const occupationReturned = OccupationFetchedData.filter(
              (data: any) =>
                data.value === responseData.data.data.occupation.occ_value
            )[0];

            setRejectMessage(responseData.data.ErrorMessage);

            setOthersDataFeild(
              responseData.data.data.occupation.occ_value === "Others"
                ? { value: "Others", sequence: "11" }
                : responseData.data.data.occupation.occ_value
            );
            setprefillData((prev) => {
              return {
                ...prev,
                fatherFullName: responseData.data.data.father_name,
                motherFullName: responseData.data.data.mother_name,
                annualIncome: annualIncomeReturned ? annualIncomeReturned : "",
                maritalStatus:
                  responseData.data.data.marital_status === "married"
                    ? MaritalOptions[1]
                    : "" || responseData.data.data.marital_status === "single"
                    ? MaritalOptions[0]
                    : "",
                areYouPoliticallyExposed:
                  responseData.data.data.political_exposed === true
                    ? PoliticalOptions[0]
                    : "" || responseData.data.data.political_exposed === false
                    ? PoliticalOptions[1]
                    : "",
                tradingExperience: tradingExperienceReturned
                  ? tradingExperienceReturned
                  : "",
                occupation: occupationReturned ? occupationReturned : "",
                occupationIfOthers:
                  responseData.data.data.occupation.others_value,

                // settlements: preferenceType ? preferenceType : Settlements[0],
              };
            });
          })
          .catch((error: any) => {
            setprefilloading(false);
            setdisplayFatherNameField(true);
            //  console.log("error", error);
          });
      })
      .catch((error: any) => {
        setprefilloading(false);
        // console.log("error", error);
      });
  }, []);

  // console.log("occupationOther",occupationOther)

  const annualIncomeOptions = annualDropDownData;
  const tradingExperienceOptions = tradingExperienceDropdownData;
  const occupationOptions = occupationDropdownData;

  function yourProfile(data: any) {
    setloading(true);
    let obj: IYourProfileDetails = {
      marital_status: data.maritalStatus.id,
      father_name: data.fatherFullName.toUpperCase(),
      mother_name: data.motherFullName.toUpperCase(),
      annual_income: data.annualIncome.value,
      trading_exp: data.tradingExperience.value,
      occupation: data.occupation.value,
      occupation_others:
        occupationOther?.value === "Others"
          ? data.occupationIfOthers.toUpperCase()
          : "",
      politically_exposed: data.areYouPoliticallyExposed.id,
      // preference: data.settlements.value.includes("month")
      //   ? "month"
      //   : "quarter",
    };
    yourProfileDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log(responseData);
        setloading(false);
        if (
          responseData.data.next_stage &&
          navigationData.hasOwnProperty(responseData.data.next_stage)
        ) {
          navigate(navigationData[responseData.data.next_stage]);
        }
      })
      .catch((error: any) => {
        setloading(false);
        setMessage(
          error.message.includes("Network")
            ? "Error occured, Please try again"
            : error.response.data.message
        );
        setTimeout(() => {
          setMessage("");
        }, 5000);
      });
  }

  const handleStartOver = () => {
    confirmAlert({
      title: "",
      message:
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => {
            setBackDrop(true);
            setstartOverloading(true);
            startOverAPICall()
              .then((response: any) => {
                const responseData = response.data;
                // console.log("responseData", response);
                setstartOverloading(false);
                setBackDrop(false);
                navigate("/");
                window.location.reload();
              })
              .catch((error: any) => {
                setBackDrop(false);
                setstartOverloading(false);
              });
          },
        },
      ],
    });
  };
  const initialValues: IYourProfilePrefillData = {
    fatherFullName: prefillData.fatherFullName,
    motherFullName: prefillData.motherFullName,
    annualIncome: prefillData.annualIncome,
    tradingExperience: prefillData.tradingExperience,
    occupation: prefillData.occupation,
    areYouPoliticallyExposed: prefillData.areYouPoliticallyExposed,
    maritalStatus: prefillData.maritalStatus,
    occupationIfOthers:
      prefillData.occupationIfOthers === null
        ? ""
        : prefillData.occupationIfOthers,
    // settlements: prefillData.settlements,
  };

  // console.log("prefillData" , prefillData)
  return (
    <>
      <SnackBar message={message} />
      <div className={ProfileStyles.desktopProgress}>
        <StageLayout
          completedStages={completedStages}
          stageName={"Personal Details"}
          navigatePath={"/aadhaarkyc"}
        />
      </div>
      <div className={ProfileStyles.mainWrapper}>
        <div className={ProfileStyles.mobileProgress}>
          <StageLayout
            completedStages={completedStages}
            stageName={"Personal Details"}
            navigatePath={"/aadhaarkyc"}
          />
        </div>
        <>
          <div className={ProfileStyles.wrapper}>
            <div className={ProfileStyles.leftContainer}>
              <div className={ProfileStyles.headerSection}>
                <p className={ProfileStyles.signup}>Your Details</p>
              </div>
              {!prefilloading ? (
                <Formik
                  validationSchema={schema}
                  initialValues={initialValues}
                  onSubmit={(values: any) => {
                    // console.log("values", values);
                    yourProfile(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => {
                    return (
                      <form
                        noValidate
                        onSubmit={handleSubmit}
                        onKeyDown={(event) => {
                          event.code === "Enter" && handleSubmit();

                          // event.code.includes("Enter") && handleSubmit()
                        }}
                      >
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={{ xs: 1, sm: 2, md: 5 }}
                        >
                          <Grid item xs={12} md={4}>
                            <p className={ProfileStyles.textfieldLabel}>
                              Enter Father’s Name
                            </p>
                            <TextField
                              className={ProfileStyles.textfield}
                              id="outlined-basic"
                              placeholder="Father's Full Name"
                              variant="outlined"
                              name="fatherFullName"
                              value={
                                values.fatherFullName &&
                                values.fatherFullName.toUpperCase()
                              }
                              error={
                                touched.fatherFullName &&
                                Boolean(errors.fatherFullName)
                              }
                              helperText={
                                touched.fatherFullName &&
                                errors.fatherFullName?.toString()
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputProps={{
                                sx: {
                                  background: "#F2F2F6",
                                  borderRadius: "8px",
                                  "&::placeholder": {
                                    paddingLeft: "10px",
                                  },

                                  paddingLeft: "10px",
                                },
                                maxLength: 50,
                                inputMode: "text",
                              }}
                              sx={{
                                fieldset: { borderColor: "transparent" },
                                width: { xs: "100%", sm: "100%" },
                                input: {
                                  color: "#344054",
                                  fontSize: "14px",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <p className={ProfileStyles.textfieldLabel}>
                              Enter Mother’s Name
                            </p>
                            <TextField
                              className={ProfileStyles.textfield}
                              id="outlined-basic"
                              placeholder="Mother's Full Name"
                              variant="outlined"
                              name="motherFullName"
                              value={
                                values.motherFullName &&
                                values.motherFullName.toUpperCase()
                              }
                              error={
                                touched.motherFullName &&
                                Boolean(errors.motherFullName)
                              }
                              helperText={
                                touched.motherFullName &&
                                errors.motherFullName?.toString()
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              inputProps={{
                                sx: {
                                  background: "#F2F2F6",
                                  borderRadius: "8px",
                                  "&::placeholder": {
                                    paddingLeft: "10px",
                                  },

                                  paddingLeft: "10px",
                                },
                                maxLength: 50,
                              }}
                              sx={{
                                fieldset: { borderColor: "transparent" },
                                width: { xs: "100%", sm: "100%" },
                                input: {
                                  color: "#344054",
                                  fontSize: "14px",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <p className={ProfileStyles.textfieldLabel}>
                              Are You a Politically Exposed Person?
                            </p>
                            <Dropdown
                              LabelName="Politically Exposed Person?"
                              menuItems={PoliticalOptions}
                              name="areYouPoliticallyExposed"
                              value={values.areYouPoliticallyExposed}
                              handleChange={handleChange}
                              error={
                                touched.areYouPoliticallyExposed &&
                                Boolean(errors.areYouPoliticallyExposed)
                              }
                              helperText={
                                touched.areYouPoliticallyExposed &&
                                errors.areYouPoliticallyExposed
                              }
                              setOthers={setOccupationOthers}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <p className={ProfileStyles.textfieldLabel}>
                              Marital Status
                            </p>
                            <Dropdown
                              LabelName="Marital Status"
                              menuItems={MaritalOptions}
                              name="maritalStatus"
                              value={values.maritalStatus}
                              handleChange={handleChange}
                              error={
                                touched.maritalStatus &&
                                Boolean(errors.maritalStatus)
                              }
                              helperText={
                                touched.maritalStatus && errors.maritalStatus
                              }
                              setOthers={setOccupationOthers}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            justifyContent={"center"}
                            margin={"auto"}
                          >
                            <p className={ProfileStyles.textfieldLabel}>
                              Trading Experience
                            </p>
                            <div style={{ width: "100%" }}>
                              <Dropdown
                                LabelName="Trading Experience"
                                menuItems={tradingExperienceOptions}
                                name="tradingExperience"
                                value={values.tradingExperience}
                                handleChange={handleChange}
                                error={
                                  touched.tradingExperience &&
                                  Boolean(errors.tradingExperience)
                                }
                                helperText={
                                  touched.tradingExperience &&
                                  errors.tradingExperience
                                }
                                setOthers={setOccupationOthers}
                              />
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            justifyContent={"center"}
                            margin={"auto"}
                          >
                            <p className={ProfileStyles.textfieldLabel}>
                              Annual Income
                            </p>
                            <div style={{ width: "100%" }}>
                              <Dropdown
                                LabelName="Annual Income"
                                menuItems={annualIncomeOptions}
                                name="annualIncome"
                                value={values.annualIncome}
                                handleChange={handleChange}
                                error={
                                  touched.annualIncome &&
                                  Boolean(errors.annualIncome)
                                }
                                helperText={
                                  touched.annualIncome && errors.annualIncome
                                }
                                setOthers={setOccupationOthers}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <p className={ProfileStyles.textfieldLabel}>
                              Occupation
                            </p>
                            <div style={{ width: "100%" }}>
                              <Dropdown
                                LabelName="Occupation"
                                menuItems={occupationOptions}
                                name="occupation"
                                value={values.occupation}
                                handleChange={handleChange}
                                error={
                                  touched.occupation &&
                                  Boolean(errors.occupation)
                                }
                                helperText={
                                  touched.occupation && errors.occupation
                                }
                                setOthers={setOthersData}
                              />
                            </div>
                          </Grid>
                          {occupationOther?.value === "Others" && (
                            <Grid item xs={12} md={4}>
                              <p className={ProfileStyles.textfieldLabel}>
                                Occupation Others
                              </p>
                              <div style={{ width: "100%" }}>
                                <TextField
                                  id="outlined-basic"
                                  placeholder="Enter Your Occupation"
                                  variant="outlined"
                                  name="occupationIfOthers"
                                  value={values.occupationIfOthers.toUpperCase()}
                                  error={
                                    touched.occupationIfOthers &&
                                    Boolean(errors.occupationIfOthers)
                                  }
                                  helperText={
                                    touched.occupationIfOthers &&
                                    errors.occupationIfOthers?.toString()
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  inputProps={{
                                    sx: {
                                      background: "#F2F2F6",
                                      borderRadius: "8px",
                                      "&::placeholder": {
                                        paddingLeft: "10px",
                                      },

                                      paddingLeft: "10px",
                                    },
                                    maxLength: 50,
                                  }}
                                  sx={{
                                    fieldset: { borderColor: "transparent" },
                                    width: { xs: "100%", sm: "100%" },
                                    input: {
                                      color: "#344054",
                                      fontSize: "14px",
                                    },
                                  }}
                                />
                              </div>
                            </Grid>
                          )}
                        </Grid>

                        <ErrorSnackBar message={rejectMessage} />
                        {backdrop && <StartOverBackDrop />}
                        <div className={ProfileStyles.buttonStack}>
                          <div>
                            <LoadingButton
                              loading={loading}
                              variant="contained"
                              type="submit"
                              id="yourProfileContinueButton"
                              onClick={() => {
                                handleSubmit();
                              }}
                              className={ProfileStyles.approveButton}
                              loadingIndicator={
                                <CircularProgress
                                  sx={{ color: "#4754A0" }}
                                  size={20}
                                />
                              }
                            >
                              Continue
                            </LoadingButton>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              ) : (
                <div style={{ height: "80vh" }}>
                  <BackDrop />
                </div>
              )}
            </div>
          </div>
        </>
      </div>
      <p className={ProfileStyles.startover}>
        {" "}
        If you want to start over, please
        <span
          className={ProfileStyles.startoverbutton}
          onClick={handleStartOver}
        >
          Click Here
        </span>
      </p>
    </>
  );
}
