import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useNavigate } from "react-router-dom";
import { checkUserLoggedIn } from "../../shared/commonfunctions";
import { Formik, Form, FieldArray } from "formik";
import {
  fetchGetNomineeDropdownValuesAPICall,
  startOverAPICall,
  stageDetailsAPICall,
  nomineeAPICall,
  deleteDocumentAPICall,
  CommonDropDownAPICall,
  nomineeDetailsAPICall,
} from "../../shared/APICalls";
import { schema } from "../../shared/FormikSchema";
import StageLayout from "../../Layouts/StageLayout";
import routesData from "../../shared/routes.json";
import Styles from "../../pages/Styles/NomineeStyles.module.scss";
import { IDeleteDocument, INominee } from "../../shared/dtos";
import icon from "../../Components/assets/Images/iconnew.svg";
import NomineeForm from "./NomineeForm";
import StartOverBackDrop from "../../Components/StartOverBackDrop";
import BackDrop from "../../Components/BackDrop";
import { v4 as uuidv4 } from "uuid";
import dayjs, { Dayjs } from "dayjs";
import { nomineeID } from "../../shared/commonData";
import ErrorSnackBar from "../../Components/SnackBars/ErrorSnackBar";
import SnackBar from "../../Components/SnackBars/SnackBar";
import FormatterCheckerNominee from "./FormatCheckerNominee";
import { UploadSizes } from "../../shared/constants";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { nomineeIDDropDown } from "../../shared/commonData";
import { confirmAlert } from "react-confirm-alert";

const statesData = [
  {
    id: "Jammu and Kashmir",
    sequence: 1,
    value: "Jammu and Kashmir",
  },
  {
    id: "Himachal Pradesh",
    sequence: 2,
    value: "Himachal Pradesh",
  },
  {
    id: "Punjab",
    sequence: 3,
    value: "Punjab",
  },
  {
    id: "Chandigarh",
    sequence: 4,
    value: "Chandigarh",
  },
  {
    id: "Uttarakhand",
    sequence: 5,
    value: "Uttarakhand",
  },
  {
    id: "Haryana",
    sequence: 6,
    value: "Haryana",
  },
  {
    id: "Delhi",
    sequence: 7,
    value: "Delhi",
  },
  {
    id: "Rajasthan",
    sequence: 8,
    value: "Rajasthan",
  },
  {
    id: "Uttar Pradesh",
    sequence: 9,
    value: "Uttar Pradesh",
  },
  {
    id: "Bihar",
    sequence: 10,
    value: "Bihar",
  },
  {
    id: "Sikkim",
    sequence: 11,
    value: "Sikkim",
  },
  {
    id: "Arunachal Pradesh",
    sequence: 12,
    value: "Arunachal Pradesh",
  },
  {
    id: "Assam",
    sequence: 13,
    value: "Assam",
  },
  {
    id: "Manipur",
    sequence: 14,
    value: "Manipur",
  },
  {
    id: "Mizoram",
    sequence: 15,
    value: "Mizoram",
  },
  {
    id: "Tripura",
    sequence: 16,
    value: "Tripura",
  },
  {
    id: "Meghalaya",
    sequence: 17,
    value: "Meghalaya",
  },
  {
    id: "Nagaland",
    sequence: 18,
    value: "Nagaland",
  },
  {
    id: "West Bengal",
    sequence: 19,
    value: "West Bengal",
  },
  {
    id: "Jharkhand",
    sequence: 20,
    value: "Jharkhand",
  },
  {
    id: "Orissa",
    sequence: 21,
    value: "Orissa",
  },
  {
    id: "Chhattisgarh",
    sequence: 22,
    value: "Chhattisgarh",
  },
  {
    id: "Madhya Pradesh",
    sequence: 23,
    value: "Madhya Pradesh",
  },
  {
    id: "Gujarat",
    sequence: 24,
    value: "Gujarat",
  },
  {
    id: "Daman and Diu",
    sequence: 25,
    value: "Daman and Diu",
  },
  {
    id: "Dadra and Nagar Haveli",
    sequence: 26,
    value: "Dadra and Nagar Haveli",
  },
  {
    id: "Maharashtra",
    sequence: 27,
    value: "Maharashtra",
  },
  {
    id: "Andhra Pradesh",
    sequence: 28,
    value: "Andhra Pradesh",
  },
  {
    id: "Karnataka",
    sequence: 29,
    value: "Karnataka",
  },
  {
    id: "Goa",
    sequence: 30,
    value: "Goa",
  },
  {
    id: "Lakshadweep",
    sequence: 31,
    value: "Lakshadweep",
  },
  {
    id: "Kerala",
    sequence: 32,
    value: "Kerala",
  },
  {
    id: "Tamil Nadu",
    sequence: 33,
    value: "Tamil Nadu",
  },
  {
    id: "Puducherry",
    sequence: 34,
    value: "Puducherry",
  },
  {
    id: "Andaman and Nicobar Islands",
    sequence: 35,
    value: "Andaman and Nicobar Islands",
  },
  {
    id: "APO",
    sequence: 36,
    value: "APO",
  },
  {
    id: "Telangana",
    sequence: 37,
    value: "Telangana",
  },
  {
    id: "Ladakh",
    sequence: 38,
    value: "Ladakh",
  },
];

export default function NomineesPage() {
  const [address, setaddress] = useState({
    address: " ",
    city: "",
    pincode: "",
    State: "",
  });
  const navigate = useNavigate();
  //   const [percentageTotal, setPercentageTotal] = useState(0);
  const [assignIndex, setAssignIndex] = useState(0);
  const [relationShipData, setRelationShipData] = useState<any>([]);

  const [relationAllData, setRelationAllData] = useState<any>();
  const [statesAllData, setStatesAllData] = useState<any>();
  const [loading, setloading] = useState(false);
  const [startOverloading, setstartOverloading] = useState(false);
  const [ageError, setageError] = useState(false);
  const [gaurdianData, setgaurdianData] = useState(false);
  const [relationError, setrelationError] = useState(false);
  const [dobError, setDobError] = useState("");
  const [completedStages, setCompletedStages] = useState<any>();
  const [backdrop, setBackDrop] = useState(false);
  const [prefilloading, setprefilloading] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const formats = ["pdf", "png", "jpg", "jpeg"];
  const [prefillData, setPrefillData] = useState<any>([
    {
      id: uuidv4(),
      nomineeFullName: "",
      nomineeEmail: "",
      nomineeAddress: "",
      nomineeDateofBirth: null,
      nomineeMobileNumber: "",
      percentageofShare: "100",
      relationShip: "",
      relationshipIfOthers: "",
      gaurdianFullName: "",
      gaurdianDateofBirth: "",
      gaurdianEmail: "",
      gaurdianMobileNumber: "",
      gaurdianRelationWithNominee: "",
      gaurdianRelationOthers: "",
      gaurdianAddress: "",
      gaurdianAddressPinCode: "",
      gaurdianAddressCity: "",
      gaurdianAddressState: "",
      nomineeIDProof: "",
      nomineeIDProofNo: "",
      nomineeAddressCity: "",
      nomineeAddressPinCode: "",
      nomineeAddressState: "",
    },
  ]);
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [nominee1document, setNominee1document] = useState("");
  const [nominee2document, setNominee2document] = useState("");
  const [nominee3document, setNominee3document] = useState("");
  const [nomineeUrl, setNomineeUrl] = useState<any>();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigationData = routesData;

  // console.log("assignIndex", assignIndex);

  const convertDayJsToDate = (dateValue: any) => {
    const date = new Date(dateValue);

    const formattedDate = date.toLocaleDateString("en-GB", {
      year: "numeric",
      day: "2-digit",
      month: "2-digit",
    });
    const [month, day, year] = formattedDate.split("/");
    const formatDate = `${year}/${day}/${month}`;

    return formatDate;
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    // checkUserLoggedIn(navigate);
    let dd_type = "nominee";
    CommonDropDownAPICall(dd_type)
      .then((response: any) => {
        const relationshipDataTemp = response.data.data.nominee_relation;
        // console.log("responseDataNominee", response.data.data.nominee_relation);
        setRelationShipData([...relationshipDataTemp]);
        let obj: any = {
          stage: "nominee",
        };

        stageDetailsAPICall(obj)
          .then((response: any) => {
            const responseData = response.data;
            setCompletedStages(responseData.data.completed_stages);
            // console.log(
            //   "responseData.data.data.nominee_proof1",
            //   responseData.data.data.nominee_proof1
            // );
            if (response.data.data.ErrorMessage) {
              setRejectMessage(response.data.data.ErrorMessage);
            }

            setprefilloading(true);
            const addr = responseData.data.data.nominee_prifill.address;
            const city = responseData.data.data.nominee_prifill.city;
            const state = responseData.data.data.nominee_prifill.state;
            const pincode = responseData.data.data.nominee_prifill.pincode;
            const clientEmail = responseData.data.data.nominee_prifill.email;
            const clientMobile = responseData.data.data.nominee_prifill.mobile;
            setCustomerEmail(clientEmail);
            setCustomerPhoneNumber(clientMobile);
            setPrefillData([
              {
                id: uuidv4(),
                nomineeFullName: "",
                nomineeEmail: clientEmail,
                nomineeAddress: "",
                nomineeDateofBirth: null,
                nomineeMobileNumber: clientMobile,
                percentageofShare: "100",
                relationShip: "",
                relationshipIfOthers: "",
                gaurdianFullName: "",
                gaurdianDateofBirth: "",
                gaurdianEmail: "",
                gaurdianMobileNumber: "",
                gaurdianRelationWithNominee: "",
                gaurdianRelationOthers: "",
                gaurdianAddress: "",
                gaurdianAddressPinCode: "",
                gaurdianAddressCity: "",
                gaurdianAddressState: "",
                nomineeIDProof: "",
                nomineeIDProofNo: "",
                nomineeAddressCity: "",
                nomineeAddressPinCode: "",
                nomineeAddressState: "",
              },
            ]);

            setaddress({
              address: addr.toUpperCase(),
              city: city.toUpperCase(),
              pincode: pincode.toUpperCase(),
              State: state.toUpperCase(),
            });
            //console.log(responseData.data.StageDetails.NomineeDetails.length)

            let nomineeIDProofURLs: any = [];

            if (responseData.data.data.nominee_proof1) {
              setNomineeUrl(responseData.data.data);
              if (responseData.data.data.nominee_proof1) {
                setNominee1document("Document Uploaded Successfully");
              }
              if (responseData.data.data.nominee_proof2) {
                setNominee2document("Document Uploaded Successfully");
              }
              if (responseData.data.data.nominee_proof3) {
                setNominee3document("Document Uploaded Successfully");
              }
            }

            if (
              responseData.data.data.nominee_details &&
              responseData.data.data.nominee_details[0].FullName !== ""
            ) {
              let nomineeDetails = responseData.data.data.nominee_details.map(
                (data: any, index: number) => ({
                  id: data.id,
                  nomineeFullName: data.FullName,
                  nomineeEmail: data.Email,
                  nomineeAddress: data.Address,
                  nomineeDateofBirth: dayjs(data.DOB),
                  nomineeMobileNumber: data.Mobile,
                  percentageofShare: data.SharePercentage,
                  relationShip: relationshipDataTemp.find(
                    (a: any) => a?.value === data.Relationship
                  ),
                  relationshipIfOthers: !data.RelationshipOthersValue
                    ? ""
                    : data.RelationshipOthersValue,
                  gaurdianFullName: !data.GuradianFullName
                    ? ""
                    : data.GuradianFullName,
                  gaurdianDateofBirth: !data.GuradianDOB
                    ? ""
                    : dayjs(data.GuradianDOB),
                  gaurdianEmail: !data.GuradianEmail ? "" : data.GuradianEmail,
                  gaurdianMobileNumber: !data.GuradianMobile
                    ? ""
                    : data.GuradianMobile,
                  gaurdianRelationWithNominee: !data.GuradianRealtionWithNominee
                    ? ""
                    : relationshipDataTemp.find(
                        (a: any) =>
                          a.value.toUpperCase() ===
                          data.GuradianRealtionWithNominee.toUpperCase()
                      ),
                  gaurdianRelationOthers: !data.GuradianRelationOthers
                    ? ""
                    : data.GuradianRelationOthers,
                  gaurdianAddress: !data.GuradianAddress
                    ? ""
                    : data.GuradianAddress,
                  gaurdianAddressPinCode: !data.GuradianAddressPinCode
                    ? ""
                    : data.GuradianAddressPinCode,
                  gaurdianAddressCity: !data.GuradianAddressCity
                    ? ""
                    : data.GuradianAddressCity,

                  gaurdianAddressState: !data.GuradianAddressState
                    ? ""
                    : statesData.filter(
                        (a: any) =>
                          a.value?.toUpperCase() ===
                          data.GuradianAddressState.toUpperCase()
                      )[0],
                  nomineeIDProof: nomineeIDDropDown.filter(
                    (a: any) => a.value === data.NomineeIDProof
                  )[0],
                  nomineeIDProofNo: !data.NomineeIDProofNo
                    ? ""
                    : data.NomineeIDProofNo,
                  nomineeAddressPinCode: data.Pincode,
                  nomineeAddressCity: data.City,
                  nomineeAddressState: statesData.filter(
                    (a: any) =>
                      a.value.toUpperCase() === data.State.toUpperCase()
                  )[0],
                })
              );
              // console.log("nomineeDetails",nomineeDetails)
              setPrefillData(nomineeDetails);
              // console.log("relationshipDataTemp", relationshipDataTemp);
            }
          })
          .catch((error: any) => {
            //console.log("error", error);
            setprefilloading(true);
          });
      })
      .catch((error: any) => {});
    // setprefilloading(true);
  }, []);

  const handleDocumentUploaded = (documentname: any) => {
    if (documentname === "Nominee Proof1") {
      setNominee1document(documentname);
    }
    if (documentname === "Nominee Proof2") {
      setNominee2document(documentname);
    }
    if (documentname === "Nominee Proof3") {
      setNominee3document(documentname);
    }
  };
  const handleDocumentDeleted = (deletedocname: any) => {
    if (deletedocname === "Nominee Proof1") {
      setNominee1document("");
    }
    if (deletedocname === "Nominee Proof2") {
      setNominee2document("");
    }
    if (deletedocname === "Nominee Proof3") {
      setNominee3document("");
    }
  };

  const handleNomineeAdd = (
    values: any,
    callBack: any,
    setFieldTouched: any
  ) => {
    setFieldTouched("nomineeFullName", false);
    setFieldTouched("nomineeEmail", false);
    setFieldTouched("nomineeAddress", false);
    setFieldTouched("nomineeDateofBirth", false);
    setFieldTouched("nomineeMobileNumber", false);
    setFieldTouched("percentageofShare", false);
    setFieldTouched("relationShip", false);
    setFieldTouched("relationshipIfOthers", false);
    setFieldTouched("gaurdianFullName", false);
    setFieldTouched("gaurdianDateofBirth", false);
    setFieldTouched("gaurdianEmail", false);
    setFieldTouched("gaurdianMobileNumber", false);
    setFieldTouched("gaurdianRelationWithNominee", false);
    setFieldTouched("nomineeIDProof", false);
    setFieldTouched("nomineeIDProofNo", false);
    setFieldTouched("nomineeAddressCity", false);
    setFieldTouched("nomineeAddressPinCode", false);
    setFieldTouched("nomineeAddressState", false);
    setAssignIndex((prev) => prev + 1);
    const obj: INominee = {
      id: uuidv4(),
      nomineeFullName: "",
      nomineeEmail: customerEmail,
      nomineeAddress: "",
      nomineeDateofBirth: "",
      nomineeMobileNumber: customerPhoneNumber,
      percentageofShare: "",
      relationShip: "",
      relationshipIfOthers: "",
      gaurdianFullName: "",
      gaurdianDateofBirth: "",
      gaurdianEmail: "",
      gaurdianMobileNumber: "",
      gaurdianRelationWithNominee: "",
      nomineeIDProof: "",
      nomineeIDProofNo: "",
      nomineeAddressCity: "",
      nomineeAddressPinCode: "",
      nomineeAddressState: "",
    };

    const nomineeCopy: any = [...values.nominees];
    nomineeCopy.push(obj);
    callBack("nominees", nomineeCopy);
  };

  const handleNomineeDelete = (values: any, callBack: any, index: any) => {
    if (index === 1) {
      if (nominee2document === "") {
        const nomineeCopy: any = [...values.nominees];
        nomineeCopy.splice(index, 1);
        callBack("nominees", nomineeCopy);
        setAssignIndex(index - 1);
        const newData = {
          ...nomineeUrl,
          [`nominee_proof${index + 1}`]: {
            ...nomineeUrl[`nominee_proof${index + 1}`],
            [`nominee_proof${index + 1}_url`]: "",
          },
        };
        setNomineeUrl(newData);
      } else {
        // window.alert("Kindly delete the uploaded document");
        let obj: any = {
          doc_purpose: `nominee_proof${index + 1}`,
        };
        setDeleteLoading(true);
        deleteDocumentAPICall(obj)
          .then((response: any) => {
            const responseData = response.data;
            setDeleteLoading(false);
            const nomineeCopy: any = [...values.nominees];
            nomineeCopy.splice(index, 1);
            callBack("nominees", nomineeCopy);
            setAssignIndex(index - 1);
            const newData = {
              ...nomineeUrl,
              [`nominee_proof${index + 1}`]: {
                ...nomineeUrl[`nominee_proof${index + 1}`],
                [`nominee_proof${index + 1}_url`]: "",
              },
            };
            setNomineeUrl(newData);
            setNominee2document("");
          })
          .catch((error: any) => {
            setDeleteLoading(false);
          });
      }
    }
    if (index === 2) {
      if (nominee3document === "") {
        const nomineeCopy: any = [...values.nominees];
        nomineeCopy.splice(index, 1);
        callBack("nominees", nomineeCopy);
        setAssignIndex(index - 1);
        const newData = {
          ...nomineeUrl,
          [`nominee_proof${index + 1}`]: {
            ...nomineeUrl[`nominee_proof${index + 1}`],
            [`nominee_proof${index + 1}_url`]: "",
          },
        };
        setNomineeUrl(newData);
      } else {
        // window.alert("Kindly delete the uploaded document");
        let obj: any = {
          doc_purpose: `nominee_proof${index + 1}`,
        };
        setDeleteLoading(true);
        deleteDocumentAPICall(obj)
          .then((response: any) => {
            const responseData = response.data;
            setDeleteLoading(false);
            const nomineeCopy: any = [...values.nominees];
            nomineeCopy.splice(index, 1);
            callBack("nominees", nomineeCopy);
            setAssignIndex(index - 1);
            const newData = {
              ...nomineeUrl,
              [`nominee_proof${index + 1}`]: {
                ...nomineeUrl[`nominee_proof${index + 1}`],
                [`nominee_proof${index + 1}_url`]: "",
              },
            };
            setNomineeUrl(newData);
          })
          .catch((error: any) => {
            setDeleteLoading(false);
          });
      }
    }
  };
  const handleChangeField = (
    name: any,
    value: any,
    callBack: any,
    index: any,
    values: any,
    setErrors: any,
    errors: any
  ) => {
    let nomineeCopy: any = [...values.nominees];
    nomineeCopy[index][name] = value;
    callBack("nominees", nomineeCopy);
    if (name === "nomineeDateofBirth") {
      if (
        values.nominees[index].nomineeDateofBirth &&
        values.nominees[index].relationShip &&
        !values.nominees[index].relationShip.guardian_req
      ) {
        const dobLimit = new Date();
        dobLimit.setFullYear(dobLimit.getFullYear() - 18);
        if (
          !values.nominees[index].nomineeDateofBirth ||
          values.nominees[index].nomineeDateofBirth > dobLimit
        ) {
          console.log("DOB is not 18 years");
        }
      }
    }
    if (name === "relationShip") {
      // Clearing Others Data

      if (
        value.label !== "Others" &&
        nomineeCopy[index]["relationshipIfOthers"]
      ) {
        nomineeCopy[index]["relationshipIfOthers"] = "";
        callBack("nominees", nomineeCopy);
      }
      if (
        values.nominees[index].nomineeDateofBirth &&
        values.nominees[index].relationShip &&
        !values.nominees[index].relationShip.guardian_req
      ) {
        const dobLimit = new Date();
        dobLimit.setFullYear(dobLimit.getFullYear() - 18);
        if (
          !values.nominees[index].nomineeDateofBirth ||
          values.nominees[index].nomineeDateofBirth > dobLimit
        ) {
          console.log("DOB is not 18 years");
        }
      }
    }

    if (name === "nomineeFullName") {
      const currentRelationShip = values.nominees[index].nomineeFullName;

      const isDuplicateRelationship = values.nominees.some((nominee, i) => {
        if (i !== index && nominee.nomineeFullName === currentRelationShip) {
          return true;
        }
        return false;
      });

      if (isDuplicateRelationship) {
        setrelationError(true);

        setTimeout(() => {
          setrelationError(false);
        }, 8000);
      }
    }
    if (name === "gaurdianDateofBirth") {
      const dobLimit = new Date();
      dobLimit.setFullYear(dobLimit.getFullYear() - 18);
      if (!value || value > dobLimit) {
        // setDobError(true);

        setDobError("Gaurdian Age should not be Below 18");
        setTimeout(() => {
          setDobError("");
        }, 10000);
      }
    }
  };

  const handleNomineeEdit = (index: any) => {
    setAssignIndex(index);
  };

  function findKeyByLabel(obj: any, label: string): string | null {
    for (const key in obj) {
      if (obj[key].label.toLowerCase() === label.toLowerCase()) {
        return key;
      }
    }
    return null;
  }

  const checkAgeLimit = (enteredDate: any) => {
    const dobLimit = new Date();
    dobLimit.setFullYear(dobLimit.getFullYear() - 18);
    if (!enteredDate || enteredDate > dobLimit) {
      return true;
    } else {
      return false;
    }
  };

  const handleStartOver = () => {
    confirmAlert({
      title: "",
      message:
        "Are you sure you want to restart, on pressing Yes all the Data will be deleted",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => {
            setBackDrop(true);
            setstartOverloading(true);
            startOverAPICall()
              .then((response: any) => {
                const responseData = response.data;
                // console.log("responseData", response);
                setstartOverloading(false);
                setBackDrop(false);
                navigate("/");
                window.location.reload();
              })
              .catch((error: any) => {
                setBackDrop(false);
                setstartOverloading(false);
              });
          },
        },
      ],
    });
  };

  function nomineeDetailsSubmit(values: any) {
    console.log("values", values);
    let ArrayObject = values.map((a: any) => ({
      id: a.id,
      FullName: a.nomineeFullName.toUpperCase().trim(),
      Email: a.nomineeEmail,
      Address: a.nomineeAddress.toUpperCase().trim(),
      DOB: convertDayJsToDate(a.nomineeDateofBirth),
      Mobile: a.nomineeMobileNumber,
      SharePercentage: a.percentageofShare,
      Relationship: a.relationShip.value,
      RelationshipName: a.relationShip.value.toUpperCase(),
      RelationshipOthersValue: a.relationshipIfOthers
        ? a.relationshipIfOthers.toUpperCase().trim()
        : "",
      GuradianFullName: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianFullName
          ? a.gaurdianFullName.toUpperCase().trim()
          : ""
        : "",
      GuradianDOB: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianDateofBirth === ""
          ? ""
          : convertDayJsToDate(a.gaurdianDateofBirth)
        : "",
      GuradianEmail: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianEmail
        : "",
      GuradianMobile: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianMobileNumber
        : "",
      GuradianRealtionWithNominee: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianRelationWithNominee.value
          ? a.gaurdianRelationWithNominee.value.toUpperCase()
          : ""
        : "",
      GuradianRelationOthers: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianRelationOthers
          ? a.gaurdianRelationOthers.toUpperCase()
          : ""
        : "",
      GuradianAddress: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianAddress
          ? a.gaurdianAddress.toUpperCase().trim()
          : ""
        : "",
      GuradianAddressPinCode: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianAddressPinCode
        : "",
      GuradianAddressCity: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianAddressCity
          ? a.gaurdianAddressCity.toUpperCase().trim()
          : ""
        : "",
      GuradianAddressState: checkAgeLimit(new Date(a.nomineeDateofBirth))
        ? a.gaurdianAddressState
          ? a.gaurdianAddressState.value.toUpperCase()
          : ""
        : "",
      NomineeIDProof: !a.nomineeIDProof ? "" : a.nomineeIDProof.value,
      NomineeIDProofNo: a.nomineeIDProofNo.toUpperCase(),
      Pincode: a.nomineeAddressPinCode,
      City: a.nomineeAddressCity.toUpperCase().trim(),
      State: a.nomineeAddressState.value.toUpperCase(),
    }));

    let obj: any = {
      nominee_json: ArrayObject,
    };
    // console.log("obj", obj);
    nomineeDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", responseData);
        setloading(false);
        if (
          responseData.data.next_stage &&
          navigationData.hasOwnProperty(responseData.data.next_stage)
        ) {
          navigate(navigationData[responseData.data.next_stage]);
        }
      })
      .catch((error: any) => {
        setloading(false);

        setMessage(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Error Occured"
        );

        setTimeout(() => {
          setMessage("");
        }, 5000);
      });
  }

  const handleSkipNominee = () => {
    let obj: any = {
      nominee_json: [],
    };
    nomineeDetailsAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", responseData);
        setloading(false);
        if (
          responseData.data.next_stage &&
          navigationData.hasOwnProperty(responseData.data.next_stage)
        ) {
          navigate(navigationData[responseData.data.next_stage]);
        }
      })
      .catch((error: any) => {
        setloading(false);

        setMessage(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Error Occured"
        );

        setTimeout(() => {
          setMessage("");
        }, 5000);
      });
  };
  // console.log("assignIndex", assignIndex);
  return (
    <>
      {" "}
      <div className={Styles.desktopProgress}>
        {" "}
        <StageLayout
          completedStages={completedStages}
          stageName={"Nominee"}
          navigatePath={"/termsandconditions"}
        />
      </div>
      <div className={Styles.mainWrapperTop}>
        <div className={Styles.mobileProgress}>
          <StageLayout
            completedStages={completedStages}
            stageName={"Nominee"}
            navigatePath={"/termsandconditions"}
          />
        </div>
        <SnackBar message={message} />
        <div className={Styles.mainWrapper}>
          <div>
            <p className={Styles.Header}>
              {assignIndex === 0
                ? "Nominee Details"
                : `Adding Nominee ${assignIndex + 1}`}
            </p>
          </div>
          {prefilloading ? (
            <Formik
              validationSchema={schema}
              initialValues={{
                nominees: [...prefillData],
              }}
              onSubmit={(values: any) => {
                // console.log("submit", values.nominees);
                // nomineeDetailsSubmit(values.nominees);
                if (values.nominees.length === 1) {
                  if (nominee1document === "") {
                    confirmAlert({
                      title: "",
                      message: "Nominee1 Id Proof should be uploaded",
                      buttons: [
                        {
                          label: "Ok",
                          onClick: () => "",
                        },
                      ],
                    });
                    // window.alert("Nominee1 Id Proof should be uploaded");
                  } else {
                    nomineeDetailsSubmit(values.nominees);
                  }
                }

                if (values.nominees.length === 2) {
                  if (nominee1document === "" || nominee2document === "") {
                    if (nominee2document === "") {
                      confirmAlert({
                        title: "",
                        message: "Nominee2 Id Proofs should be uploaded",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => "",
                          },
                        ],
                      });
                      // window.alert("Nominee2 Id Proofs should be uploaded");
                    } else {
                      confirmAlert({
                        title: "",
                        message: "Nominee1 Id Proofs should be uploaded",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => "",
                          },
                        ],
                      });
                      // window.alert("Nominee1 Id Proofs should be uploaded");
                    }
                  } else {
                    nomineeDetailsSubmit(values.nominees);
                  }
                }
                if (values.nominees.length === 3) {
                  if (
                    nominee1document === "" ||
                    nominee2document === "" ||
                    nominee3document === ""
                  ) {
                    if (nominee3document === "") {
                      confirmAlert({
                        title: "",
                        message: "Nominee3 Id Proofs should be uploaded",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => "",
                          },
                        ],
                      });
                      // window.alert("Nominee3 Id Proofs should be uploaded");
                    } else if (nominee2document === "") {
                      confirmAlert({
                        title: "",
                        message: "Nominee2 Id Proofs should be uploaded",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => "",
                          },
                        ],
                      });
                      // window.alert("Nominee2 Id Proofs should be uploaded");
                    } else {
                      confirmAlert({
                        title: "",
                        message: "Nominee1 Id Proofs should be uploaded",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => "",
                          },
                        ],
                      });
                      // window.alert("Nominee1 Id Proofs should be uploaded");
                    }
                  } else {
                    nomineeDetailsSubmit(values.nominees);
                  }
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldError,
                setErrors,
                setFieldTouched,
              }) => {
                const { nominees } = values;
                 console.log("errors", errors);
                //  console.log("values", values);

                const percentageTotal = values.nominees
                  .map((a: any) =>
                    a.percentageofShare === ""
                      ? 0
                      : parseFloat(a.percentageofShare)
                  )
                  .reduce((sum: any, current: any) => sum + current, 0);
                // console.log("errors", errors);
                return (
                  <Form>
                    <div className={Styles.wrapper}>
                      <div className={Styles.leftContainer}>
                        <FieldArray
                          name="nominees"
                          render={(arrayHelpers) => (
                            <div>
                              {nominees.map((nominee, index) => {
                                const touchedData = touched.nominees
                                  ? touched.nominees[index]
                                  : {};
                                const errorData = errors.nominees
                                  ? errors.nominees[index]
                                  : {};

                                return (
                                  <NomineeForm
                                    handleNomineeDelete={handleNomineeDelete}
                                    handleNomineeEdit={handleNomineeEdit}
                                    index={index}
                                    currentIndex={assignIndex}
                                    nominee={nominee}
                                    touchedData={touched}
                                    errorData={errorData}
                                    handleChangeField={handleChangeField}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    handleBlur={handleBlur}
                                    relationError={false}
                                    ageError={false}
                                    relationShipData={relationShipData}
                                    address={address}
                                    setErrors={setErrors}
                                    errors={errors}
                                    gaurdianData={gaurdianData}
                                    statesData={statesData}
                                  />
                                );
                              })}
                            </div>
                          )}
                        />
                      </div>

                      <ErrorSnackBar message={rejectMessage} />
                      {backdrop && <StartOverBackDrop />}
                      <div className={Styles.rightContainer}>
                        <div className={Styles.ButtonSection}>
                          <div className={Styles.Buttons}>
                            <div>
                              {assignIndex !== 2 ? (
                                <Button
                                  className={Styles.addNomineeButton}
                                  onClick={() => {
                                    handleNomineeAdd(
                                      values,
                                      setFieldValue,
                                      setFieldTouched
                                    );
                                    window.scrollTo(0, 0);
                                  }}
                                  disabled={
                                    percentageTotal >= 100 ||
                                    assignIndex === 2 ||
                                    Object.keys(errors).length > 0 ||
                                    Object.keys(touched).length === 0
                                  }
                                  id="nomineesAddNomineeButton"
                                  startIcon={
                                    <HighlightOffIcon
                                      style={{ transform: "rotate(45deg)" }}
                                    />
                                  }
                                  style={{
                                    height: "40px",
                                    padding: "0px 15px",
                                    fontWeight: 600,
                                    borderRadius: "8px",
                                    boxShadow: "none",
                                  }}
                                  variant="contained"
                                >
                                  Add Nominee {assignIndex + 2}
                                </Button>
                              ) : (
                                <Button
                                  className={Styles.addNomineeButton}
                                  onClick={() => {
                                    handleNomineeAdd(
                                      values,
                                      setFieldValue,
                                      setFieldTouched
                                    );
                                    window.scrollTo(0, 0);
                                  }}
                                  disabled={
                                    percentageTotal >= 100 ||
                                    assignIndex === 2 ||
                                    Object.keys(errors).length > 0 ||
                                    Object.keys(touched).length === 0
                                  }
                                  id="nomineesAddNomineeButton"
                                  style={{
                                    height: "40px",
                                    padding: "0px 15px",
                                    fontWeight: 600,
                                    borderRadius: "8px",
                                    boxShadow: "none",
                                  }}
                                  variant="contained"
                                >
                                  Add Nominee 3
                                </Button>
                              )}
                            </div>
                          </div>
                          {nominees.map((nominee, index) => {
                            return (
                              <div
                                className={Styles.uploadSignatureSection}
                                style={{
                                  display:
                                    assignIndex !== index ? "none" : "flex",
                                }}
                              >
                                <div>
                                  <FormatterCheckerNominee
                                    acceptedFormats={formats}
                                    previewImage={() => {}}
                                    documentRef={`nominee_proof${index + 1}`}
                                    documentID={handleDocumentUploaded}
                                    documentDeleted={handleDocumentDeleted}
                                    displayOptions={""}
                                    stageDetailsData={
                                      nomineeUrl &&
                                      nomineeUrl[`nominee_proof${index + 1}`]
                                        ?.url
                                    }
                                    name={`Nominee Proof${index + 1}`}
                                    purpose={`nominee_proof${index + 1}`}
                                    pdfSize={UploadSizes["nominee"]?.pdfSize}
                                    imageSize={
                                      UploadSizes["nominee"]?.imageSize
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className={Styles.rightContainerContinue}>
                        <div>
                          <Button
                            id="tradingPreferencesContinueButton"
                            variant="text"
                            className={Styles.backButton}
                            sx={{
                              background: "#EEEFEF",
                              "&:hover": {
                                background: "#EEEFEF",
                              },
                            }}
                            onClick={() => {
                              navigate("/termsandconditions");
                            }}
                          >
                            Back
                          </Button>
                        </div>
                        <div>
                          {percentageTotal !== 100 ? (
                            <LoadingButton
                              variant="contained"
                              type="submit"
                              // onClick={nomineeDetailsSubmit}
                              disabled={
                                values.nominees[0].nomineeFullName === "" ||
                                values.nominees[0].relationShip === "" ||
                                percentageTotal !== 100 ||
                                Object.keys(errors).length > 0
                              }
                              loading={loading}
                              id="nomineesContinueButton"
                              className={Styles.addNomineeButtonContinue}
                              loadingIndicator={
                                <CircularProgress
                                  sx={{ color: "#4754A0" }}
                                  size={20}
                                />
                              }
                            >
                              {percentageTotal > 100
                                ? "Percentage is > 100"
                                : "Percentage is < 100"}
                            </LoadingButton>
                          ) : (
                            <LoadingButton
                              variant="contained"
                              type="submit"
                              // onClick={nomineeDetailsSubmit}
                              disabled={
                                values.nominees[0].nomineeFullName === "" ||
                                values.nominees[0].relationShip === "" ||
                                percentageTotal !== 100 ||
                                Object.keys(errors).length > 0
                              }
                              loading={loading}
                              id="nomineesContinueButton"
                              className={Styles.addNomineeButtonContinue}
                              loadingIndicator={
                                <CircularProgress
                                  sx={{ color: "#4754A0" }}
                                  size={20}
                                />
                              }
                            >
                              Continue
                            </LoadingButton>
                          )}
                        </div>
                        <div>
                          <Button
                            id="tradingPreferencesContinueButton"
                            variant="text"
                            className={Styles.backButton}
                            sx={{
                              background: "#EEEFEF",
                              "&:hover": {
                                background: "#EEEFEF",
                              },
                            }}
                            onClick={() => {
                              handleSkipNominee();
                            }}
                          >
                            Skip
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <Stack height={"90vh"}>
              <BackDrop />
            </Stack>
          )}
        </div>
      </div>
      <p className={Styles.startover}>
        {" "}
        If you want to start over, please
        <span className={Styles.startoverbutton} onClick={handleStartOver}>
          Click Here
        </span>
      </p>
    </>
  );
}
