import React from "react";
import FooterStyles from "./Footer.module.scss";

import TradingViewIcon from "../../Components/assets/Images/tradingViewIcon.svg";
import InstaIcon from "../../Components/assets/Images/instaIcon.svg";
import FbIcon from "../../Components/assets/Images/fbIcon.svg";
import LinkedIcon from "../../Components/assets/Images/linkedIcon.svg";
import YouTubeIcon from "../../Components/assets/Images/youTubeIcon.svg";
import TwitterIcon from "../../Components/assets/Images/twitterIcon.svg";

const Footer = () => {
  return (
    <>
      <div className={FooterStyles.wrapper}>
        <div className={FooterStyles.wrapperTop}>
          <div className={FooterStyles.wrapperTopContent}>
            <div className={FooterStyles.logoSection}>
              <div className={FooterStyles.siteMapingAlign}>
                <div className={FooterStyles.linksandsublinksLeft}>
                  <a target="_blank" className={FooterStyles.sublinksnot}>
                    <span className={FooterStyles.Title}>
                      Composite Investments Pvt. Ltd.
                    </span>
                  </a>
                  <a
                    target="_blank"
                    className={FooterStyles.sublinksnotAddress}
                  >
                    30/1, 3rd Floor, SJ Towers Mission Road, Bangalore - 560 027
                    Karnataka, India Phone: 080 – 41145828 / 29 Mobile: 98447
                    00001.
                  </a>
                </div>
              </div>
            </div>
            <div className={FooterStyles.siteMaping}>
              <div className={FooterStyles.siteMapingAlign}>
                <div className={FooterStyles.linksandsublinks}>
                  <a target="_blank" className={FooterStyles.siteMapingLinks}>
                    Company
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>Our Story</span>
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>
                      Testimonials
                    </span>
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>We Support</span>
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>
                      Our Branches
                    </span>{" "}
                  </a>
                </div>

                <div className={FooterStyles.linksandsublinks}>
                  <a target="_blank" className={FooterStyles.siteMapingLinks}>
                    Services
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>
                      Depository Services
                    </span>
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>
                      Transmission / IEPF
                    </span>
                  </a>

                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>
                      Portfolio Management (PMS)
                    </span>
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>
                      Composite Insurance
                    </span>
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>
                      NRI & Foreign Nationals
                    </span>
                  </a>
                </div>

                <div className={FooterStyles.linksandsublinks}>
                  <a target="_blank" className={FooterStyles.siteMapingLinks}>
                    Support
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>
                      Learning Centre
                    </span>
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>Downloads</span>
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>
                      Fund Transfer
                    </span>
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>
                      Customer care
                    </span>
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>Contact Us</span>
                  </a>
                  <a href="" target="_blank" className={FooterStyles.sublinks}>
                    <span className={FooterStyles.toUnderline}>
                      Mandatory Information
                    </span>
                  </a>
                </div>
              </div>

            
            </div>
          </div>

          <div className={FooterStyles.bottomExtraContent}>
            <div className={FooterStyles.contentsectionnew}>
              <div className={FooterStyles.privacyPolicyLinks}>
                <p
                  className={FooterStyles.copyRightPara}
                  style={{ margin: "5px 0" }}
                >
                  2022 Copyright Composite Investments Pvt. Ltd. |
                  <a
                    href=""
                    style={{ color: "#4853A2", textDecoration: "none" }}
                  >
                    Privacy Policy
                  </a>
                </p>
                <div className={FooterStyles.imageSection}>
                  <div className={FooterStyles.followusicons}>
                    <a href="" target="_blank">
                      <img
                        src={TradingViewIcon}
                        alt="image"
                        className={FooterStyles.icons}
                        style={{ width: "80%" }}
                      />
                    </a>
                    <a href="https://www.instagram.com/compositedge/?igshid=YmMyMTA2M2Y%3D" target="_blank">
                      <img
                        src={InstaIcon}
                        alt="image"
                        className={FooterStyles.icons}
                      />
                    </a>
                    <a href="https://www.facebook.com/compositedge/" target="_blank">
                      <img
                        src={FbIcon}
                        alt="image"
                        className={FooterStyles.icons}
                      />
                    </a>

                    <a href="https://www.linkedin.com/company/composite-investments/" target="_blank">
                      <img
                        src={LinkedIcon}
                        alt="image"
                        className={FooterStyles.icons}
                      />
                    </a>
                    <a href="https://www.youtube.com/channel/UCM9v8sZDw-Okk8smX2W-avQ" target="_blank">
                      <img
                        src={YouTubeIcon}
                        alt="image"
                        className={FooterStyles.icons}
                      />
                    </a>
                    <a href="https://x.com/compositeinv" target="_blank">
                      <img
                        src={TwitterIcon}
                        alt="image"
                        className={FooterStyles.icons}
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="" style={{ textAlign: "left" }}>
                  <div>
                    <p className={FooterStyles.footerHeader}>Disclosures</p>
                    <div className="">
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        Composite Investments Pvt Ltd is a registered member of
                        SEBI. SEBI Registration No. INZ000279531, PMS SEBI
                        Registration No. INP000006396, CDSL DP:
                        IN-DP-CDSL-43-2015, Commodities - MCX: 10565 FMC: TCM
                        CORPO161. NSE: 7520, BSE: 6370
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        Prevent Unauthorized Transactions in your demat account.
                        Update your Mobile Number with your Depository
                        Participant. Receive alerts on your Registered Mobile
                        for all debits and other important transactions in your
                        demat account directly from CDSL the same day.
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        Investors are advised not to subscribe to any scheme or
                        products / arrangements offered by any entities and
                        persons, involving assured / guaranteed returns or
                        unauthorised portfolio management / collective
                        investment schemes. Such arrangements / schemes are not
                        permitted under the SEBI / NSE Bye-Law Rules and
                        Regulations.
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        KYC is a one-time exercise while dealing in the
                        securities market. Once your KYC is done through a SEBI
                        registered intermediary i.e., Broker, DP, Mutual Fund,
                        etc, you need not undergo the same process again when
                        you approach another intermediary.
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        Kindly contact{" "}
                        <a
                          href=""
                          target="_blank"
                          className={FooterStyles.alink}
                        >
                          help@compositeinvestments.com
                        </a>
                         to update / add / modify: Bank account details / Demat
                        account details / Email id / Mobile number.
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        Please
                        <a
                          href=""
                          target="_blank"
                          className={FooterStyles.alink}
                        >
                           click here{" "}
                        </a>
                        for the KYC form in a vernacular Language
                      </p>
                    </div>

                    <p className={FooterStyles.footerHeader}>
                      Mandatory document on the NSE website
                    </p>

                    <div className="">
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        Filing complaints on SCORES - Easy & Quick:{" "}
                        <a
                          href=""
                          target="_blank"
                          className={FooterStyles.alink}
                        >
                          https://www.scores.gov.in
                        </a>
                      </p>
                    </div>
                    <p className={FooterStyles.footerHeader}>Important Link</p>
                    <div className="">
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        BSE -{" "}
                        <a
                          href=" https://www.bseindia.com/"
                          target="_blank"
                          className={FooterStyles.alink}
                        >
                          https://www.bseindia.com/
                        </a>
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        NSE -{" "}
                        <a
                          href=" https://www.bseindia.com/"
                          target="_blank"
                          className={FooterStyles.alink}
                        >
                          https://www.nseindia.com/
                        </a>
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        SEBI -{" "}
                        <a
                          href=" https://www.bseindia.com/"
                          target="_blank"
                          className={FooterStyles.alink}
                        >
                          https://www.sebi.gov.in/
                        </a>
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        <a
                          href=" https://www.bseindia.com/"
                          target="_blank"
                          className={FooterStyles.alink}
                        >
                          https://investor.sebi.gov.in/
                        </a>
                      </p>
                    </div>

                    <p
                      className=""
                      style={{
                        fontSize: "13px",
                        margin: "10px 0",
                        color: "#475467",
                        marginTop: "20px",
                      }}
                    >
                      1. Register on the SCORES portal
                    </p>
                    <p
                      className=""
                      style={{
                        fontSize: "13px",
                        margin: "10px 0",
                        color: "#475467",
                      }}
                    >
                      2. Mandatory details for filing complaints on SCORES i.e.,
                      Name, PAN, Address, Mobile Number, E-mail ID
                    </p>
                    <p
                      className=""
                      style={{
                        fontSize: "13px",
                        margin: "10px 0",
                        color: "#475467",
                      }}
                    >
                      3. Benefits: i. Effective Communication ii. Speedy
                      redressal of grievances
                    </p>

                    <p className={FooterStyles.footerHeader}>
                      Investor Awareness
                    </p>

                    <div className="">
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        DearInvestor,
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "5px 0",
                          color: "#475467",
                        }}
                      >
                        As you are aware, under the rapidly evolving dynamics of
                        financial markets, it is crucial for investors to remain
                        updated and well-informed about various aspects of
                        investing in the securities market. In this connection,
                        please find a link to the BSE Investor Protection Fund
                        website where you will find some useful educative
                        material in the form of text and videos, so as to become
                        an informed investor.
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        <a
                          href="https://www.bseipf.com/investors_education.html"
                          target="_blank"
                          className={FooterStyles.alink}
                        >
                          https://www.bseipf.com/investors_education.html
                        </a>
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "5px 0",
                          color: "#475467",
                        }}
                      >
                        We believe that an educated investor is a protected
                        investor !
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "#475467",
                        }}
                      >
                        Policy and Procedures:
                      </p>

                      <p
                        className={FooterStyles.copyRightPara}
                        style={{ margin: "5px 0" }}
                      >
                        <a href="" style={{ color: "#4853A2" }}>
                          AML Policy
                        </a>{" "}
                        |{" "}
                        <a href="" style={{ color: "#4853A2" }}>
                          Auction Policy
                        </a>{" "}
                        |{" "}
                        <a href="" style={{ color: "#4853A2" }}>
                          Policy on Inactive Client Accounts
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
